
import { Vue, Component } from 'vue-property-decorator'

@Component({
  filters: {
    statusFilters (value: string) {
      const name = value === 'N' ? '禁用' : value === 'Y' ? '启用' : '执行中'
      return name
    }
  },
  computed: {
    disableUpdate () {
      return function (row: {endDate: string | number;planStatus: string}) {
        return (new Date(row.endDate).getTime() + 1000 * 60 * 60 * 24) < (new Date()).getTime() || row.planStatus === 'S'
      }
    }
  }
})
export default class IrrigationSmartPlan extends Vue {
  private tableData = {
    loading: false,
    tr: [
      {
        label: '计划名称',
        prop: 'planName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'planStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '开始时间',
        prop: 'startDate',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '结束时间',
        prop: 'endDate',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '创建时间',
        prop: 'ctime',
        minWidth: '150',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  page = 1
  size = 10
  total = 0
  created () {
    this.loadData()
  }

  loadData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.irrigation.irrigationPlanPage, {
      page: this.page,
      size: this.size,
      planType: '2',
      projectId: this.$route.query.projectId
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onAdd () {
    this.$router.push({
      name: 'irrigationAddSmart',
      query: { projectId: this.$route.query.projectId }
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'irrigationUpdateSmart',
      query: { projectId: this.$route.query.projectId },
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.irrigation.irrigationPlanDelete, {
          planId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.loadData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'irrigationDetailSmart',
      query: { projectId: this.$route.query.projectId },
      params: { id }
    })
  }

  onOpen (id: string) {
    this.$axios.post(this.$apis.irrigation.irrigationPlanOpen, {
      planId: id
    }).then(() => {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.loadData()
    })
  }
}
